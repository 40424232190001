<!-- 线上课程 -->
<template>
  <div class="resourceManage fx-col fx-1">
    <template v-if="!uploadFileShow">
      <el-card>
        <div slot="header">
          <el-form
            class="form-item-no-mb"
            ref="searchForm"
            inline
            :model="searchForm"
            size="small"
          >
            <el-form-item label="课程名称">
              <el-input
                v-model="searchForm.courseName"
                clearable
                placeholder="请输入课程名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程编号">
              <el-input
                v-model="searchForm.courseCode"
                clearable
                placeholder="请输入课程编号"
              ></el-input>
            </el-form-item>
            <el-form-item label="课程类型">
              <el-select
                v-model="searchForm.courseTypeId"
                clearable
                placeholder="请选择课程类型"
              >
                <el-option
                  v-for="item in courseTypeList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="更新时间">
              <el-date-picker
              v-model="searchForm.updateTime"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
            </el-form-item>
            <el-form-item label="发布状态">
              <el-select
                v-model="searchForm.status"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in publishStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button @click="_reset">重置</el-button>
              <el-button type="primary" @click="_query()">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" icon="el-icon-plus" @click="_addCoursePopup(1)"
            >新增课程</el-button
          >
        </div>
      </el-card>
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 资源分组 -->
        <ResourceGrouping
        title="课程分组"
          :treeData="treeData"
          :defaultProps="defaultProps"
          @clickGroupNode="_clickGroupNode"
          @editGroupHandle="_editGroupHandle"
          @deleteGrouphandle="_deleteGrouphandle"
          @addGroupHandle="_addGroupHandle"
        />
        <!-- 列表 -->
        <div class="w200 fx-1">
          <!-- 表格 -->
          <CourseTable
            :tableData="tableData"
            @courseDetail="_courseDetail"
            @deleteCourse="_deleteCourse"
            type="online"
          />
          <!-- 分页 -->
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="searchForm.total"
          >
          </el-pagination>
        </div>
      </div>
    </template>
    <!-- 列表预览 -->
    <PreviewFile
      v-if="previewFileShow"
      :previewFileShow="previewFileShow"
      ref="previewFile"
    ></PreviewFile>
    <!-- 资源分组编辑 -->
    <EditGroup
      v-if="editGroupShow"
      :editGroupShow="editGroupShow"
      :treeData="treeDataGroup"
      :defaultProps="defaultProps"
      :currentNode="currentNode"
      @editSubmitGroup="_editSubmitGroup"
      type="course"
      pageValue="otherGroup"
      ref="editGroup"
    ></EditGroup>
    <!-- 资源分组添加 -->
    <AddGroup
      v-if="addGroupShow"
      :addGroupShow="addGroupShow"
      :treeData="treeData"
      :defaultProps="defaultProps"
      @submiAddGroup="_submiAddGroup"
      ref="addGroup"
    ></AddGroup>
    <!-- 分组删除 -->
    <DeleteGroup v-if="deleteGroupShow" :deleteGroupShow="deleteGroupShow" />
    <!-- 添加课程 -->
    <AddCourse
      v-if="addCourseShow"
      :addCourseShow="addCourseShow"
      :courseTypeList="courseTypeList"
      :treeData="treeData"
      :defaultProps="defaultProps"

      @submiCourse="_submiCourse"
    />
  </div>
</template>
<script>
import EditGroup from '@/components/editGroup/index.vue'
import AddGroup from '@/components/addGroup/index.vue'
import PreviewFile from '@/components/previewFile/index.vue'
import DeleteGroup from '@/components/deleteGroup/index.vue'
import ResourceGrouping from '@/components/resourceGrouping/index.vue'
import AddCourse from '../components/addCourse.vue'
import CourseTable from '../components/courseTable.vue'
import course from '@/mixins/course'
export default {
  mixins: [course],
  components: {
    EditGroup,
    AddGroup,
    PreviewFile,
    DeleteGroup,
    ResourceGrouping,
    AddCourse,
    CourseTable
  },
  created () {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
.add-group-btn {
  width: 116px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.delete-group-btn {
  width: 60px;
  border: 1px solid #f04134;
  font-size: 14px;
  color: #f04134;
}
/deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
/deep/.el-form-item__label {
  color: #000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0 10px 0px 0!important;
}
</style>
